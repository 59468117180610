import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" alt="A image showing two examples of buttons with counters" src="https://user-images.githubusercontent.com/586552/205141782-bfe137b1-6d83-4b9d-bcdd-bcd1877f864b.png" />
    <h2>{`Usage`}</h2>
    <p>{`Use counter label to add a count to navigational elements and buttons.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`Always use counter label with adjacent text that provides supplementary information regarding what the count is for. For instance, Counter should be accompanied with text such as issues or pull requests.`}</p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="CounterLabel" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      